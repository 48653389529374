<template>
  <div>
    <div
      class="
        consume-bar
        margin-top-bottom-twenty
        box-shadow-light-grey
        border-radius-6
        padding-right-left-5
      "
    >
      <el-menu
        :default-active="activeSubMenu"
        active-text-color="#00bf8a"
        mode="horizontal"
        class="border-radius-6"
        router
      >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/order/consumeCharging"
          @click.native="handleTabClick('/statistics/order/consumeCharging')"
          >消耗充值</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/order/orderDetail"
          @click.native="handleTabClick('/statistics/order/orderDetail')"
          >订单明细</el-menu-item
        >
      </el-menu>
    </div>
    <div class="consume-part">
      <router-view></router-view>
      <!-- <consume-charging v-if="tabName === '/statistics/order/consumeCharging'" />
      <order-detail v-if="tabName === '/statistics/order/orderDetail'" /> -->
    </div>
  </div>
</template>

<script>
// import ConsumeCharging from "./consumeCharging.vue";
// import OrderDetail from "./orderDetail";
export default {
  name: "order",
  // components: {
  //   ConsumeCharging,
  //   OrderDetail,
  // },
  data() {
    return {
      tabName: this.$route.path,
      activeSubMenu: this.$route.path,
    };
  },
  watch: {
    $route(newVal) {
      this.activeSubMenu = newVal.path;
      this.tabName = newVal.path;
    },
  },
  methods: {
    handleTabClick(tab) {
      this.tabName = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
// /deep/ .el-menu--horizontal > .el-menu-item.is-active{
//   border-right:none !important;
//   background-color:#fff !important;
//   border-bottom: 2px solid #00bf8a !important;
// }
.consume-bar {
  background: #fff;
  /deep/ .el-menu-item,
  .el-submenu__title {
    height: 34px;
  }
}
.el-menu--horizontal > .el-menu-item {
  height: 52px;
  line-height: 52px;
}
.consume-part {
  box-shadow: 0px 0px 6px 0px #f1f4f6;
}
</style>
